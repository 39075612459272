import { Box, Grid, Typography } from '@mui/material'
import React, { MutableRefObject, useContext, useEffect, useRef } from 'react'
import SearchInput from '../common/SearchInput'
import { OptionsButton, SpecialButton, StandardButton } from '../styles/buttons'
import { MenuHeaderDivider } from '../styles/header';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import UserContext from '../contexts/UserContext';
import SelectButton from '../common/SelectButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useParams, useSearchParams } from 'react-router-dom';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { QRCode } from 'react-qrcode-logo';
import { primary } from '../styles/colors';

type Props = {
    onSave: () => void;
    onNewCategoryClick: () => void;
    search: string;
    setSearch: (search: string) => void;
}

const MenuEditorHeader = (props: Props) => {
    const { t } = useTranslation();
    const { selectedRestaurant, restaurantSettings, setSelectedRestaurant } = useContext(UserContext);
    const params = useParams();
    const qrRef = useRef<QRCode>();

    function selectOptions() {
        return restaurantSettings.map((restaurantSetting) => {
            return {
                value: restaurantSetting.restaurantShortName,
                label: restaurantSetting.name
            }
        });
    }

    function handleRestaurantChange(value: string | null) {
        const restaurant = restaurantSettings.find(restaurant => restaurant.restaurantShortName === value);
        if (restaurant) {
            setSelectedRestaurant(restaurant);
        }
    }

    function getMenuUrl() {
        return `${process.env.REACT_APP_FRONTEND_URL}/menu/${selectedRestaurant?.restaurantShortName}/${params.lang || "hu"}`;
    }

    function handleMenuButtonClick() {
        if (selectedRestaurant == null) return;
        window.open(getMenuUrl(), '_blank');
    }

    function handleQRButtonClick() {
        qrRef.current?.download('png', `${selectedRestaurant?.restaurantShortName}-menu-qr-code.png`);
    }

    return (
        <Box pr={1}>
            <Grid container spacing={2}>
                <Grid item xs>
                    <Typography variant="h4" fontWeight={"bold"} fontSize="26px">
                        {t("menu.menu_editor")}
                    </Typography>
                </Grid>
                <Grid item>
                    <SearchInput
                        search={props.search}
                        setSearch={props.setSearch}
                    />
                </Grid>
                <Grid item>
                    {/*
                      <OptionsButton>
                        <img src={"../assets/list.svg"} style={{ marginTop: "2px", marginLeft: "1px" }} />
                    </OptionsButton>
                    */}

                </Grid>
            </Grid>
            <Box pt={4}>
                <Grid container spacing={2}>
                    {restaurantSettings.length > 1 && (
                        <Grid item xs>
                            <SelectButton
                                options={selectOptions()}
                                onChange={handleRestaurantChange}
                                selectedValue={selectedRestaurant != null ? selectedRestaurant.restaurantShortName : null}
                            />
                        </Grid>
                    )}
                    {restaurantSettings.length < 2 && (
                        <Grid item xs>
                            <Grid container spacing={3}>
                                <Grid item>
                                    <Typography pt={2} variant="h4" fontWeight={"bold"} fontSize="20px">
                                        {selectedRestaurant?.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item>
                        <StandardButton
                            onClick={props.onSave}
                        >
                            {t("common.save")}
                        </StandardButton>
                    </Grid>
                    <Grid item>
                        <SpecialButton
                            onClick={handleMenuButtonClick}
                        >
                            {t("menu.menu")}
                            <OpenInNewIcon className="end-icon" />
                        </SpecialButton>
                    </Grid>
                    <Grid item>
                        <SpecialButton
                            onClick={handleQRButtonClick}
                        >
                            {t("menu.generate_qr")}
                            <QrCode2Icon className="end-icon" />
                        </SpecialButton>
                        <QRCode
                            style={{ display: "none" }}
                            ref={qrRef as MutableRefObject<QRCode>}
                            value={getMenuUrl()}
                            ecLevel='H'
                            size={300}
                            fgColor={primary.dark}
                            logoImage='../assets/logo-dark.svg'
                            logoPadding={8}
                            logoPaddingStyle='circle'
                            removeQrCodeBehindLogo={true}
                            qrStyle='squares'
                        />
                    </Grid>
                    <Grid item>
                        <SpecialButton
                            onClick={props.onNewCategoryClick}
                        >
                            {t("menu.new_category")}
                            <AddIcon className="end-icon" />
                        </SpecialButton>
                    </Grid>
                </Grid>
            </Box>
            <Box pt={2}>
                <MenuHeaderDivider />
            </Box>
        </Box>
    )
}

export default MenuEditorHeader