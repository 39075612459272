import React, { useContext, useMemo, useRef } from 'react'
import { MenuItem } from '../models/MenuItem'
import { availableLang } from '../models/Lang';
import { Alert, Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StandardInput, StandardTextArea, } from '../styles/inputs';
import { InputContainer, MenuItemEditRowContainer } from '../styles/containers';
import { PrimarySwitch } from '../styles/switches';
import NumericInput from '../common/NumericInput';
import { ErrorIconButton, MenuIndexIconButton, SelectButton } from '../styles/buttons';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { errorColor, primary } from '../styles/colors';
import { SortableList } from '../sortable/SortableList';
import MenuItemEditRowHandle from './MenuItemEditRowHandle';
import { MenuItemIndexImage, MenuItemIndexImageContainer } from '../styles/images';
import useFetch from '../hooks/useFetch';
import { postUploadItemImage } from '../config/apiRoutes';
import UserContext from '../contexts/UserContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandIcon from '../icons/ExpandIcon';
import AllergenAddMenuButton from './AllergenAddMenuButton';
import { Allergen } from '../models/Allergens';
import AllergenSelector from './AllergenSelector';

type Props = {
    menuItem: MenuItem;
    setMenuItem: (menuItem: MenuItem) => void;
    selectedLang: availableLang;
    onRemoveClick: () => void;
    isReorderAllowed?: boolean;
}

const maxFileSizeMb = 25;

const MenuItemEditRow = ({ menuItem, selectedLang, setMenuItem, onRemoveClick, isReorderAllowed }: Props) => {
    const { t } = useTranslation();
    const { selectedRestaurant } = useContext(UserContext);
    const uploader = useFetch(false, true);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const title = useMemo(() => {
        const relevantTranslation = menuItem.translations.find(translation => translation.isoCode.toLowerCase() === selectedLang);
        if (relevantTranslation) return relevantTranslation.title;
        return "";
    }, [selectedLang, menuItem.translations]);

    const description = useMemo(() => {
        const relevantTranslation = menuItem.translations.find(translation => translation.isoCode.toLowerCase() === selectedLang);
        if (relevantTranslation) return relevantTranslation.description;
        return "";
    }, [selectedLang, menuItem.translations]);

    function handleTitleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const relevantTranslation = menuItem.translations.find(translation => translation.isoCode.toLowerCase() === selectedLang);
        if (!relevantTranslation) return;
        const newTranslation = {
            ...relevantTranslation,
            title: e.target.value
        }
        if (relevantTranslation) {
            setMenuItem({
                ...menuItem,
                translations: menuItem.translations.map(translation => translation.id === relevantTranslation.id ? newTranslation : translation)
            });
        }
    }

    function handleDescriptionChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        const relevantTranslation = menuItem.translations.find(translation => translation.isoCode.toLowerCase() === selectedLang);
        if (!relevantTranslation) return;
        const newTranslation = {
            ...relevantTranslation,
            description: e.target.value
        }
        if (relevantTranslation) {
            setMenuItem({
                ...menuItem,
                translations: menuItem.translations.map(translation => translation.id === relevantTranslation.id ? newTranslation : translation)
            });
        }
    }

    function handlePriceChange(newPrice: number) {
        setMenuItem({
            ...menuItem,
            priceGross: newPrice
        });
    }

    function handleIsActiveCHange(e: React.ChangeEvent<HTMLInputElement>) {
        setMenuItem({
            ...menuItem,
            isActive: e.target.checked
        });
    }

    function handleFileUploadClick() {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    async function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.files) return;
        if (selectedRestaurant == null) return;
        const sizeInBytes = e.target.files[0].size / 1024 / 1024;
        if (sizeInBytes > maxFileSizeMb) {
            setErrorMessage(t("gallery.maximum_file_size"));
            return false;
        }
        const formData = new FormData();
        formData.append("file", e.target.files[0]);

        const response = await uploader.call<any, any>("POST", postUploadItemImage.replace(":restaurantShortName", selectedRestaurant?.restaurantShortName), formData);
        if (response.ok && response.data) {
            setMenuItem({
                ...menuItem,
                imageUrl: response.data.originalUrl
            });
            setErrorMessage(null);
            return true;
        } else {
            return false;
        }
    }

    function setAllergens(newAllergens: Allergen[]) {
        setMenuItem({
            ...menuItem,
            allergens: newAllergens
        });
    }

    return (
        <>
            <SortableList.Item id={menuItem.id}>
                <MenuItemEditRowContainer pt={2.5} pb={2} px={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={1}>
                            <Typography fontSize={"12px"} fontWeight={600} mb={0.5}>
                                {t("menu.index_image")}
                            </Typography>
                            <MenuItemIndexImageContainer>
                                <MenuItemIndexImage
                                    src={menuItem.imageUrl || "../assets/logo.svg"}
                                    style={{
                                        transform: menuItem.imageUrl ? "scale(1)" : "scale(0.7)",
                                    }}
                                />
                                <MenuIndexIconButton
                                    onClick={handleFileUploadClick}
                                    disabled={uploader.loading}
                                >
                                    {uploader.loading && (
                                        <CircularProgress
                                            size="16px"
                                            style={{ color: primary.light }}
                                        />
                                    )}
                                    {!uploader.loading && (
                                        <img
                                            src="../assets/open-img.svg"
                                            width="16px"
                                            height="16px"
                                        />
                                    )}
                                </MenuIndexIconButton>
                            </MenuItemIndexImageContainer>
                            <input
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                type="file"
                                accept="image/*"
                                onChange={handleFileUpload}
                            />
                        </Grid>
                        <Grid item xs={2} >
                            <Typography fontSize={"12px"} fontWeight={600} mb={0.5}>
                                {t("common.name")}:
                            </Typography>
                            <StandardInput
                                value={title}
                                onChange={handleTitleChange}
                            />
                            <Typography fontSize={"12px"} fontWeight={600} pt={1} pb={0.5}>
                                {t("common.status")}:
                            </Typography>
                            <InputContainer>
                                <Box px={1.75}>
                                    <Grid container>
                                        <Grid item xs>
                                            <Typography fontSize="14px" pt={0.8}>
                                                {menuItem.isActive ? t("common.available") : t("common.unavailable")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <Box pt={0.5} textAlign={"right"}>
                                                <PrimarySwitch size="small"
                                                    checked={menuItem.isActive}
                                                    onChange={handleIsActiveCHange}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </InputContainer>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography fontSize={"12px"} fontWeight={600} mb={0.5}>
                                {t("common.description")}:
                            </Typography>
                            <Box>
                                <StandardTextArea
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    style={{ height: "100px" }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs>
                            <Grid container>
                                <Grid item xs>
                                    <Typography fontSize={"12px"} fontWeight={600} mb={0.5}>
                                        {t("menu.allergens")}:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Box textAlign={"right"} style={{ marginTop: "-10px" }}>
                                        <AllergenAddMenuButton
                                            allergens={menuItem.allergens}
                                            setAllergens={setAllergens}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <AllergenSelector
                                allergens={menuItem.allergens}
                                setAllergens={setAllergens}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography fontSize={"12px"} fontWeight={600} pb={0.5}>
                                {t("common.price")}:
                            </Typography>
                            <Box>
                                <NumericInput
                                    value={menuItem.priceGross}
                                    unitOfMeasure='Ft'
                                    setValue={handlePriceChange}
                                />
                            </Box>
                            <Box mt={3}>
                                <ErrorIconButton
                                    onClick={onRemoveClick}
                                >
                                    <DeleteForeverIcon style={{ color: errorColor }} />
                                </ErrorIconButton>
                            </Box>
                        </Grid>
                        {isReorderAllowed && (
                            <Grid item style={{ width: "50px" }}>
                                <MenuItemEditRowHandle />
                            </Grid>
                        )}

                    </Grid>
                    {errorMessage && (
                        <Alert severity="error">{errorMessage}</Alert>
                    )}
                    {uploader.error && (
                        <Alert severity="error">{uploader.errorMessage}</Alert>
                    )}
                </MenuItemEditRowContainer>
            </SortableList.Item>
        </>
    )
}

export default MenuItemEditRow