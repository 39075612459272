import commonEn from "../en/commonEn";

const commonHu: typeof commonEn = {
    "restaurant": "Étterem",
    "restaurant_not_found": "A keresett étterem nem elérhető",
    "table_not_available": "Ennél az asztalnál nem adható le rendelés",
    "note": "Megjegyzés",
    "order": "Rendelés",
    "internal_error": "Kérés sikertelen. A szerver oldali hiba: ",
    "no_results": "Nincs találat",
    "cancel": "Mégse",
    "save": "Mentés",
    "back": "Vissza",
    "search": "Keresés",
    "search_for_file_names": "Keresés file név szerint",
    "delete": "Törlés",
    "search_by_restaurant": "Keresés étterem szerint",
    "available": "Elérhető",
    "unavailable": "Nem elérhető",
    "name": "Név",
    "status": "Állapot",
    "description": "Leírás",
    "price": "Ár",
    "add": "Hozzáadás",
    "phone": "Telefon",
    "internal_server_error": "Internal server error",
    "remove_all": "Összes törlése",
}

export default commonHu;
