import { Grid, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { allowedLangOptions } from '../config/header';
import { usePathReconstructor } from '../config/pageRoutes';
import { LangSelectorContainer } from '../styles/containers';
import LangSelectorButton from './LangSelectorButton';

const LangSelector = () => {
    const { replaceParam } = usePathReconstructor();
    const { i18n } = useTranslation();

    function handleLanguageSelection(newLang: string) {
        i18n.changeLanguage(newLang);
        syncURL(newLang);
    };

    function syncURL(newLang: string) {
        const newURL = replaceParam("lang", newLang);
        if (newURL != null) {
            window.history.replaceState(null, document.title, newURL);
        }
    }

    return (
        <>
            <LangSelectorContainer>
                <Box py={0.5} px={0.5}>
                    <Grid container spacing={1}>
                        {allowedLangOptions.map((lang) => (
                            <Grid item key={lang.iso}>
                                <LangSelectorButton
                                    isSelected={i18n.language === lang.value}
                                    onClick={() => { handleLanguageSelection(lang.value) }}
                                    imgUrl={process.env.REACT_APP_PUBLIC_URL + '/assets/flags/' + lang.iso.toLowerCase() + '.png'}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </LangSelectorContainer>
        </>)
}

export default LangSelector