import React, { useMemo, useState } from 'react'
import { MenuCategory } from '../models/MenuCategory'
import { Box, Collapse, Grid } from '@mui/material';
import LangSelectorTextfield from './LangSelectorTextfield';
import { availableLang } from '../models/Lang';
import { RoundedMainContainer, RoundedWhiteContainer } from '../styles/containers';
import LabeledSwitch from '../common/LabeledSwitch';
import { PrimarySwitch } from '../styles/switches';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CollapseIconButton, ErrorIconButton, SmallSpecialButton } from '../styles/buttons';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { errorColor } from '../styles/colors';
import { useTranslation } from 'react-i18next';
import MenuItemEditRow from './MenuItemEditRow';
import { MenuItem, defaultMenuItemTranslations } from '../models/MenuItem';
import { SortableList } from '../sortable/SortableList';
import MenuItemEditCategoryRowHandle from './MenuItemEditCategoryRowHandle';
import AddIcon from '@mui/icons-material/Add';

type Props = {
  menuCategory: MenuCategory;
  setMenuCategory: (menuCategory: MenuCategory) => void;
  onDeleteClick: () => void;
  forcedMenuItems?: MenuItem[];
  isReorderAllowed?: boolean;
  forceOpen?: boolean;
}

const EditedMenuCategory = ({ menuCategory, setMenuCategory, onDeleteClick, forceOpen, isReorderAllowed, forcedMenuItems }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(forceOpen || false);
  const [selectedLang, setSelectedLang] = useState<availableLang>("hu");

  const selectedTranslation = useMemo(() => {
    const relevantTranslation = menuCategory.translations.find(translation => translation.isoCode.toLowerCase() === selectedLang);
    if (relevantTranslation) return relevantTranslation.title;
    return "";
  }, [selectedLang, menuCategory.translations]);

  function handleTranslationTitleChange(newTitle: string) {
    const relevantTranslation = menuCategory.translations.find(translation => translation.isoCode.toLowerCase() === selectedLang);
    if (!relevantTranslation) return;
    const newTranslation = {
      ...relevantTranslation,
      title: newTitle
    }
    if (relevantTranslation) {
      setMenuCategory({
        ...menuCategory,
        translations: menuCategory.translations.map(translation => translation.id === relevantTranslation.id ? newTranslation : translation)
      });
    }
  }

  function handleAvailableToggle(e: React.ChangeEvent<HTMLInputElement>) {
    setMenuCategory({
      ...menuCategory,
      isActive: e.target.checked
    });
  }

  function setMenuItem(newMenuItem: MenuItem) {
    setMenuCategory({
      ...menuCategory,
      menuItems: menuCategory.menuItems.map(menuItem => menuItem.id === newMenuItem.id ? newMenuItem : menuItem)
    });
  }

  function setMenuItems(newMenuItems: MenuItem[]) {
    setMenuCategory({
      ...menuCategory,
      menuItems: newMenuItems
    });
  }

  function removeItem(toRemoveId: number) {
    setMenuCategory({
      ...menuCategory,
      menuItems: menuCategory.menuItems.filter(menuItem => menuItem.id !== toRemoveId)
    });
  }

  function handleNewCategoryClick() {
    const newOrder = menuCategory.menuItems.length > 0 ? menuCategory.menuItems[menuCategory.menuItems.length - 1].order + 1 : 1;
    const newItem: MenuItem = {
      id: (new Date()).getTime() * -1,
      priceGross: 0,
      order: newOrder,
      isActive: false,
      imageUrl: null,
      translations: defaultMenuItemTranslations,
      availabilityType: '',
      allergens: []
    };

    setMenuCategory({
      ...menuCategory,
      menuItems: [...menuCategory.menuItems, newItem]
    });
    setIsOpen(true);
  }

  function isItemReorderAllowed() {
    return forcedMenuItems?.length === menuCategory.menuItems.length;
  }

  return (
    <>
      <SortableList.Item id={menuCategory.id}>
        <Box py={2}>
          <RoundedMainContainer p={2}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <LangSelectorTextfield
                  value={selectedTranslation}
                  setValue={handleTranslationTitleChange}
                  selectedLang={selectedLang}
                  setSelectedLang={setSelectedLang}
                />
              </Grid>
              <Grid item>
                <RoundedWhiteContainer p={0.5}>
                  <Grid container>
                    <Grid item>
                      <Box pl={1} pt={0.8}>
                        {t("common.available")}
                      </Box>
                    </Grid>
                    <Grid item>
                      <PrimarySwitch
                        checked={menuCategory.isActive}
                        onChange={handleAvailableToggle}
                      />
                    </Grid>
                  </Grid>
                </RoundedWhiteContainer>
                <LabeledSwitch />
              </Grid>
              <Grid item xs>
                <Box mt={0.5}>
                  <Grid container spacing={1} justifyContent={"flex-end"}>
                    <Grid item>
                      <SmallSpecialButton
                        onClick={handleNewCategoryClick}
                      >
                        {t("menu.new_item")}
                        <AddIcon className="end-icon" />
                      </SmallSpecialButton>
                    </Grid>
                    <Grid item>
                      <ErrorIconButton
                        onClick={onDeleteClick}
                      >
                        <DeleteForeverIcon style={{ color: errorColor }} />
                      </ErrorIconButton>
                    </Grid>
                    <Grid item>
                      <CollapseIconButton open={isOpen} onClick={() => { setIsOpen(!isOpen) }}>
                        {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </CollapseIconButton>
                    </Grid>
                    <Grid item>
                      {isReorderAllowed && (
                        <MenuItemEditCategoryRowHandle />
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </RoundedMainContainer>
          <Collapse in={isOpen}>
            <Box>
              {forcedMenuItems == null && (
                <SortableList
                  items={menuCategory.menuItems}
                  onChange={setMenuItems}
                  displayMode='list'
                  renderItem={(menuItem) => (<MenuItemEditRow
                    key={menuItem.id}
                    menuItem={menuItem}
                    selectedLang={selectedLang}
                    setMenuItem={setMenuItem}
                    onRemoveClick={() => removeItem(menuItem.id)}
                    isReorderAllowed={true}
                  />)}
                />
              )}
              {forcedMenuItems != null && (
                <>
                  <SortableList
                    items={forcedMenuItems}
                    onChange={setMenuItems}
                    displayMode='list'
                    renderItem={(menuItem) => (<MenuItemEditRow
                      key={menuItem.id}
                      menuItem={menuItem}
                      selectedLang={selectedLang}
                      setMenuItem={setMenuItem}
                      onRemoveClick={() => removeItem(menuItem.id)}
                      isReorderAllowed={isItemReorderAllowed()}
                    />)}
                  />
                </>
              )}

            </Box>
          </Collapse>
        </Box>
      </SortableList.Item>
    </>
  )
}

export default EditedMenuCategory