
export enum AllergenType {
    GLUTEN = "GLUTEN",
    CRUSTACEANS = "CRUSTACEANS",
    EGGS = "EGGS",
    FISH = "FISH",
    PEANUTS = "PEANUTS",
    SOYBEANS = "SOYBEANS",
    MILK = "MILK",
    NUTS = "NUTS",
    CELERY = "CELERY",
    MUSTARD = "MUSTARD",
    SESAME = "SESAME",
    SULPHUR_DIOXIDE = "SULPHUR_DIOXIDE",
    LUPIN = "LUPIN",
    MOLLUSCS = "MOLLUSCS",
}

export interface Allergen {
    id: number;
    type: AllergenType;
}

export const allergenOrderDict = {
    [AllergenType.GLUTEN]: 1,
    [AllergenType.CRUSTACEANS]: 2,
    [AllergenType.EGGS]: 3,
    [AllergenType.FISH]: 4,
    [AllergenType.PEANUTS]: 5,
    [AllergenType.SOYBEANS]: 6,
    [AllergenType.MILK]: 7,
    [AllergenType.NUTS]: 8,
    [AllergenType.CELERY]: 9,
    [AllergenType.MUSTARD]: 10,
    [AllergenType.SESAME]: 11,
    [AllergenType.SULPHUR_DIOXIDE]: 12,
    [AllergenType.LUPIN]: 13,
    [AllergenType.MOLLUSCS]: 14,
}

export function allergenComparer(allergen1: Allergen, allergen2: Allergen) {
    return allergenOrderDict[allergen1.type] - allergenOrderDict[allergen2.type];
}